<template>
  <div class="contentbody">
    <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="contentleft" v-if="w.left" v-bind:class="{ contentleftsmall: w.right }">
      <div class="contentleft-inner">
        <div class="contentleft-header">
          <div class="mr5" @click="CreateNote()">
            <b-button icon-left="plus">New </b-button>
          </div>
          <div class="taskSearchSel">
            <b-select v-model="ActiveGoalId" icon="bullseye-arrow" @input="setActiveGoal();doSearch();" expanded>
              <option v-for="(g, gi) in GoalList" :key="gi" :value="g._id">{{g.goaltitle}}</option>
            </b-select>
          </div>
          <div class="contentleft-header-searchbox">
            <b-input placeholder="Search" type="search" icon="search" v-model="searchField" @input="doSearch()" ></b-input>
          </div>
        </div>

        <div class="contentleft-body scrolly scrollx">
          <div class="tsk lgreybg p11 m11" v-if="noteList.length === 0">No notes created</div>
          <div class="tsk p7 m7 isFlex" v-for="(n, index) in noteList" :key="index" @click="openNote(n, index)">
            <div class="ml11 isFlexGrow">
              <div class="txtsm">Created by: {{ n.subname }} Date: {{ n.updatedAt | displaydateFull }} </div>
              <div class="tskhead">{{ n.title }}</div>
            </div>
          </div>
        </div>
        <div class="p3 isFlex">
          <div>
            <b-pagination
              @change="getNotes($event)"
              :total="total"
              :current="current"
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :order="order"
              :size="size"
              :simple="isSimple"
              :rounded="isRounded"
              :per-page="perPage"
              :icon-prev="prevIcon"
              :icon-next="nextIcon"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="contentright" v-if="w.right">
      <div class="contentright-inner">
        <div class="contentright-nav">
          <div class="iconbutton mv5" @click="closeRight()">
            <font-awesome-icon :icon="['fal', 'times']" size="lg" ></font-awesome-icon>
          </div>
          <div v-if="ActiveNote.canEdit">
            <div v-if="!isEdit">
              <div class="iconbutton mv5" @click="isEdit=true">
                <font-awesome-icon :icon="['fal', 'edit']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div v-if="isEdit">
              <div class="iconbutton mv5" @click="isEdit=false">
                <font-awesome-icon :icon="['fal', 'eye']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="iconbutton mv11 bluetxt11" @click="noteSave()">
              <font-awesome-icon :icon="['fal', 'save']" size="lg" ></font-awesome-icon>
            </div>
            <div class="iconbutton mv11 red" @click="noteDelete()">
              <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
            </div>
          </div>
        </div>
        <div class="contentright-body ">
          <div class="bluebg1 pb3 pt11">
            <div v-if="isEdit" class="pl9 pr9">
              <b-select placeholder="Select Goal" expanded v-model="ActiveNote.goalid" @input="changeGoal">
                <option v-for="gc in GoalList" :value="gc._id" :key="gc._id">{{ gc.goaltitle }}</option>
              </b-select>
            </div>
            <div v-if="!isEdit && ActiveNote.goaltitle !== ''" class="pl9">Goal: {{ActiveNote.goaltitle}}</div>

            <div v-if="isEdit" class="mt5 pl9 pr9 mb11">
              <b-input v-model="ActiveNote.title" placeholder="Please enter a title"></b-input>
              <div class="field mt5" v-if="isadmin"><b-switch v-model="ActiveNote.demo">Demo</b-switch></div>
            </div>
              <div v-else class="txth5 txtbold mb5 pl9">{{ActiveNote.title}}</div>
              <div class="bluebg2">
                <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                  <div class="menubar">
                    <div class="iconbutton m3 edbut" @click="commands.bold" :class="{ 'is-active': isActive.bold() }" ><font-awesome-icon :icon="['fal', 'bold']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.italic" :class="{ 'is-active': isActive.italic() }"><font-awesome-icon :icon="['fal', 'italic']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.strike" :class="{ 'is-active': isActive.strike() }" ><font-awesome-icon :icon="['fal', 'strikethrough']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.underline" :class="{ 'is-active': isActive.underline() }" ><font-awesome-icon :icon="['fal', 'underline']" size="sm" ></font-awesome-icon></div>
                    <!-- <div class="iconbutton m3 edbut" @click="commands.code" :class="{ 'is-active': isActive.code() }"><font-awesome-icon :icon="['fal', 'code']" size="sm"></font-awesome-icon></div> -->
                    <div class="iconbutton m3 edbut" @click="commands.heading({ level: 1 })" :class="{ 'is-active': isActive.heading({ level: 1 }) }" ><font-awesome-icon :icon="['fal', 'h1']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.heading({ level: 2 })" :class="{ 'is-active': isActive.heading({ level: 2 }) }" ><font-awesome-icon :icon="['fal', 'h2']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.heading({ level: 3 })" :class="{ 'is-active': isActive.heading({ level: 3 }) }" ><font-awesome-icon :icon="['fal', 'h3']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.bullet_list" :class="{ 'is-active': isActive.bullet_list() }" ><font-awesome-icon :icon="['fal', 'list-ul']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.ordered_list" :class="{ 'is-active': isActive.ordered_list() }" ><font-awesome-icon :icon="['fal', 'list-ol']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.todo_list" :class="{ 'is-active': isActive.todo_list() }" ><font-awesome-icon :icon="['fal', 'tasks']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.code_block" :class="{ 'is-active': isActive.code_block() }" ><font-awesome-icon :icon="['fal', 'code']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.horizontal_rule"><font-awesome-icon :icon="['fal', 'horizontal-rule']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.undo"><font-awesome-icon :icon="['fal', 'undo']" size="sm" ></font-awesome-icon></div>
                    <div class="iconbutton m3 edbut" @click="commands.redo"><font-awesome-icon :icon="['fal', 'redo']" size="sm" ></font-awesome-icon></div>
                  </div>
                </editor-menu-bar>
              </div>
          </div>
          <div class="contentright-body-body scrolly">
            <div>
              
              <div v-if="showEditor">
                
                <div style="height: 10px;"></div>
                <editor-content :editor="editor" style/>
              </div>
            </div>

          </div>
          <div class="p11 bluebg11" v-if="ActiveNote.type === 'Chat' || ActiveNote.type === 'Request'">
            <div><b-input v-model="newReply" maxlength="500" rows="2" type="textarea" placeholder="Post a reply"></b-input></div>
            <div class="buttons">
              <b-button expanded @click="postReply">Post Reply</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import array from "lodash/array";
import debounce from "lodash/debounce";
import { windowSplit2 } from "../mixins/windowSplit.js";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote, // quote-right
  CodeBlock, // code
  Heading, // h1, h2, h3, h4
  HorizontalRule, // horizontal-rule
  OrderedList, // list-ol
  BulletList, // list-ul
  ListItem, //
  TodoItem,
  TodoList, // tasks
  Bold, // bold
  Code, // code ?
  Italic, // italic
  Link, // link
  Strike, // strikethrough
  Underline, //underline
  History // undo, redo
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  mixins: [Utils, windowSplit2],
  name: 'Notes',
  data() {
    return {
      editor: null,
      noteList: [],
      peopleList: [],
      searchField: '',
      ActiveNote: {},
      ActiveNoteId: -1,
      isEdit: false,
      showEditor: false,
      canEdit: false,
      isNew: false,
      newNotify: {
        to: [],
        subject: '',
        message: '',
        type: 'Chat',
        goalid: '',
        goaltitle: '',
        goalcolor: '',
      },
      isLoading: true,
      newReply: '',
      GoalList: [],
      sub: localStorage.getItem("sub"),

      // pagination: start
      searchField: "",
      perPage: 20,
      prodCount: 0,
      total: 0,
      current: 1,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      isadmin: localStorage.getItem("isadmin"),
      // pagination: end
    }
  },
  created() {
    this.getGoalSelect();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getNotes(0);
    // this.getGoals();
  },
  methods: {
    
    async getGoals() {
      this.GoalList = [];

      const glin = await this.DataGet("/goals/select");
      const gl = glin;

      // if(this.isNew) {
      //   this.GoalList.push(this.goalSelected);
      // } else {
      //   this.goalSelected = this.inGoal;
      //   this.GoalList.push(this.goalSelected);
      // }

      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      if(!this.isNew) {
        this.GoalList.push({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      }
      this.loading = false;
    },

    doSearch: debounce(function() {
      this.current = 1;
      this.total = 0;
      this.getNotes(0);
    }, 2000),

    async getNotes(type) {
      this.isLoading = true;

      const sendData = {
        searchField: this.searchField,
        goalid: this.ActiveGoalId,
        perPage: this.perPage,
        skip: 0
      };
      this.current = type;
      if (type === 0) {
        this.current = 1;
        let getcount = await this.DataSend('post','/notes/listcount', sendData, 'rows');
        let totalRows = getcount;
        if (totalRows > this.perPage) {
          totalRows = totalRows / this.perPage;
          totalRows = Math.ceil(totalRows);
          this.total = totalRows * this.perPage;
        } else {
          this.total = totalRows;
        }
      }

      if (type > 1) {
        sendData.skip = this.perPage * (type - 1);
      }

      const gnotify = await this.DataSend('post', '/notes', sendData, 'rows');
      
      this.noteList = gnotify;
      this.isLoading = false;
    },

    async openNote(row, index) {
      this.ActiveNoteId = index;
      this.editor = null;
      this.showEditor = false;
      this.isLoading = true;
      this.isNew = false;
      const gc = await this.DataGet(`/note/${row._id}`);

      this.ActiveNote = gc;
      this.openRight();
      this.editNote()
      this.isLoading = false;
    },

    editNote(){
      this.showEditor = true;
      (this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem({ nested: true }),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History()
      ],
      content: this.ActiveNote.notehtml,
      onUpdate: ({ getJSON, getHTML }) => {
        this.json = getJSON();
        // this.html = getHTML();
        this.ActiveNote.notehtml = getHTML();
      }
      })),
      this.editor.focus();
    },

    changeGoal(data){
      if (data !== '') {
        const gindex = array.findLastIndex(this.GoalList, { '_id': data });
        this.ActiveNote.goalid = data;
        this.ActiveNote.goaltitle = this.GoalList[gindex].goaltitle;
        this.ActiveNote.goalcolor = this.GoalList[gindex].goalcolor;
      } else {
        this.ActiveNote.goalid = '';
        this.ActiveNote.goaltitle = '';
        this.ActiveNote.goalcolor = '';
      }
    },

    async CreateNote() {
      const newnote = await this.DataGet('/notenew');
      this.noteList.unshift(newnote);
      this.openNote(newnote, 0);
    },

    async noteSave() {
      const dosave = await this.DataSend('put', '/note', this.ActiveNote, 'rows', true);
      this.noteList[this.ActiveNoteId].title = dosave.title;
    },

    async noteDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this note? This cannot be undone.",
        confirmText: "Delete Note",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.closeRight();
          const delnote = await this.DataDelete(`/note/${this.ActiveNote._id}`, "row");
          this.noteList.splice(this.ActiveNoteId, 1);
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // EventBus.$off('task_open');
    // EventBus.$off('task_added');
  }
}
</script>

<style>
.ProseMirror {
  /* border: 1px solid #CCC; */
  min-height: 300px;
  padding: 10px;
}
.ProseMirror > h1 {
  font-size: 2em;
}
.ProseMirror > h2 {
  font-size: 1.7em;
}
.ProseMirror > h3 {
  font-size: 1.4em;
}
.ProseMirror > ul {
  list-style-type: circle !important;
  padding-left: 30px !important;
}
.ProseMirror > ol {
  padding-left: 40px !important;
}
.ProfileDesc > p{
  margin-bottom: 40px;
}
</style>
